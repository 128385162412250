import React from "react";
import Layout from "../components/layout";
import { privacyData } from "../utilities/tncdata";

import "./styles/tncs.css";

function PrivacyPolicy(){
    return (
        <Layout>
            <h2 className="tncs-header"> {"Privacy Policy"} </h2>
            <div className="tncs">
                <div> {`At SimplyFi, we understand the importance of your privacy, and we are committed to protecting it. 
                    This Privacy Policy explains how we collect, use, and disclose personal information about you when you use our website, JoinSimplyFi.com. 
                    This policy applies to all users of the website, including visitors, registered users, and customers.
                    Information we collect We collect personal information from you when you use our credit card recommendation platform. 
                    Personal information includes your name, email address, mailing address, phone number, date of birth, and employment information. 
                    Financial information includes your income, credit score, and credit card usage habits. Financial documents may include bank statements and credit reports.`} 
                </div>

                <div> 
                    {privacyData.map( ele => (
                        <div>
                            <h3> {ele.title}  </h3>
                            <div> {ele.description} </div>
                        </div>
                    ) )}
                    
                </div>  
            </div>
        </Layout>
    )
}

export default PrivacyPolicy;